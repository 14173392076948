<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Presupuesto</h4>
            <div class="small text-muted">Crear y administrar todos los presupuestos</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card id="list-budgets" v-if="table.mostrarFiltros">
        <b-row >
          <b-col sm="10">            
            <b-row>            
              <b-col sm="12" class="pb-1">
                <b-row>   
                  <b-col md="3" class="p-1 pb-1">
                    <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                  </b-col>
                  <b-col md="3" class="p-1 pb-1">
                    <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                  </b-col>                               
                  <b-col sm="3" class="p-1 pb-1">
                    <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>
                </b-row>     
              </b-col>  
              <b-col sm="12">
                <b-row>         
                  <b-col sm="3" class="p-1">
                    <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>                   
                  <b-col sm="3" v-if="moduleSellersActive" class="p-1">
                    <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>
                  <b-col sm="3" class="p-1">
                    <v-select :options="arr.filters.status" v-model="filters.status" placeholder="Estado" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>                         
                  <b-col sm="3" v-if="moduleSalesActive" class="p-1">
                    <v-select :options="arr.filters.invoiced" v-model="filters.invoiced" placeholder="Facturado" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>
                  <b-col sm="3" class="p-1">
                    <v-select :options="arr.filters.recurrent" v-model="filters.recurrent" placeholder="Recurrente Mensual" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>                  
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="2" class="p-1">
            <b-row>
              <b-col sm="12">                        
                <b-button variant="outline-dark" @click="filterBudgets()" size="sm"  class="pull-right">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>   
              </b-col>     
              <b-col sm="12">                                 
                <b-link>
                  <export-excel
                    class = 'pull-right mt-3'
                    :data = "arr.export"
                    worksheet = "Listado de Pedidos"
                    name = "list-budgets.xls">
                    Exportar Datos
                  </export-excel>            
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>              
      </b-card>

      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_budgets">
          <b-card>
            <b-row>              
              <b-col md="5">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="number" 
                                  placeholder="Buscar por Nº de Presupuesto" 
                                  v-model="filters.number"
                                  v-on:keyup.enter.native="filterBudgets()"
                                  size="sm">
                    </b-form-input>   
                    <b-input-group-append>
                      <b-button size="sm" variant="secondary" title="Filtrar" @click="filterBudgets()">
                        <b-icon icon="filter"></b-icon>
                      </b-button>
                    </b-input-group-append>                               
                  </b-input-group>
                </b-form-group>                                    
              </b-col>
              <b-col>                
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Presupuesto">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>                
              </b-col>
              <b-col sm="12">
                <b-table class="mb-0 table-budgets-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"  
                        :busy="table.isBusy"                      
                        v-if="table.items.length || table.isBusy">                       
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>
                    
                    <template v-slot:cell(business)="data">
                      {{data.item.point_sale.business.reference}}
                    </template>

                    <template v-slot:cell(id)="data">
                      <div v-if="data.item.type_voucher && data.item.point_sale">
                        {{data.item.type_voucher.name}} <br>
                        {{data.item.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.number.toString().padStart(8,'0')}}
                      </div>
                    </template>
                    
                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD/MM/YYYY')}}
                    </template>

                    <template v-slot:cell(date_expiration)="data">
                      {{moment(data.item.date_expiration).format('DD/MM/YYYY')}}
                    </template>                    

                    <template v-slot:cell(sellers_id)="data">                      
                      <span v-if="data.item.sellers_id" v-b-tooltip.hover :title="'VENDEDOR: ' + data.item.seller.staff.name">
                        <b-avatar :src="data.item.seller.staff.image"
                                  v-if="data.item.seller.staff.image">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark">
                        </b-avatar>                             
                      </span>       
                    </template>

                    <template v-slot:cell(staff_id)="data">                      
                      <span v-if="data.item.staff_id" v-b-tooltip.hover :title="'STAFF: ' + data.item.staff.name"> 
                        <b-avatar :src="data.item.staff.image"
                                  v-if="data.item.staff.image">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark">
                        </b-avatar>              
                      </span>               
                    </template>
                    
                    <template v-slot:cell(customers_id)="data">  
                      <div v-if="data.item.customer" :title="data.item.contact">            
                        <b-avatar :src="data.item.customer.image"
                                  v-if="data.item.customer.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"                                            
                                  variant="dark">
                        </b-avatar>                                                     
                        &nbsp;<b>{{data.item.customer.name}}</b>   
                        
                        <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                      </div>
                    </template>

                    <template v-slot:cell(amount_total)="data">  
                      <div v-if="data.item.currency" :title="data.item.currency.name" v-b-tooltip.hover>
                        <b-icon icon="arrow-repeat" v-if="data.item.recurrent" v-b-tooltip.hover title="Recurrente (Mensual)" class="mr-1" />
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount_total)}}</b>                        
                      </div>                      
                    </template>

                    <template v-slot:cell(observations)="data">
                      <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                        <b-icon icon="chat-square-text"></b-icon>
                      </div>                                              
                    </template>
                    
                    <template v-slot:cell(observations_internal)="data">           
                      <div v-if="data.item.observations_internal" v-b-tooltip.hover :title="data.item.observations_internal">
                        <b-icon icon="chat-square-text" variant="info"></b-icon>
                      </div>                                                                    
                    </template>

                    <template v-slot:cell(invoice)="data">
                      <div v-if="data.item.sale_id" v-b-tooltip.hover :title="data.item.sale.type_voucher.name + ' ' + data.item.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.sale.number.toString().padStart(8,'0')">
                        <b-icon icon="receipt-cutoff"></b-icon>
                      </div>                                              
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableBudgetsShow"
                                  @hide="setConfigTableBudgetsHide">
                        
                        <b-dropdown-item @click="sendPrinterBudget(data.item)">
                          <b-icon icon="printer"></b-icon> Imprimir
                        </b-dropdown-item>                          
                        <b-dropdown-item @click="setTemplate(data.item)" v-if="showStatusOpen(data.item)">
                          <i class="fa fa-file-text-o crud-budget-action-icon"></i> Contenido 
                        </b-dropdown-item>                                    

                        <b-dropdown-header v-if="showGenerateInvoice(data.item)">
                          ESTADO
                        </b-dropdown-header> 
                        <b-dropdown-item @click="openStatus(data.item)" v-if="showGenerateInvoice(data.item)">
                          <b-icon icon="stoplights-fill"></b-icon> Cambiar Estado
                        </b-dropdown-item>   

                        <b-dropdown-header v-if="showGenerateInvoice(data.item) && showStatusWon(data.item)">
                          ERP
                        </b-dropdown-header> 
                        <b-dropdown-item @click="generateInvoice(data.item)" v-if="showGenerateInvoice(data.item) && showStatusWon(data.item)">
                          <b-icon icon="file-earmark-ruled"></b-icon> Facturar
                        </b-dropdown-item>   

                        <b-dropdown-header v-if="showGenerateInvoice(data.item) && showStatusOpen(data.item)">Acciones</b-dropdown-header>
                        <b-dropdown-item @click="edit(data.item)" v-if="showGenerateInvoice(data.item) && showStatusOpen(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>                        
                        <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deleteBudgets && showGenerateInvoice(data.item) && showStatusOpen(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- PRESUPUESTO SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="8">
                      <div class="crud-budgets-title" v-if="itemSelected">
                        <div class="crud-budgets-title">
                          <span class="crud-budgets-code" v-if="itemSelected.type_voucher && itemSelected.point_sale">                          
                            {{itemSelected.type_voucher.name}} {{itemSelected.point_sale.point_sale.toString().padStart(4,'0') + '-' + itemSelected.number.toString().padStart(8,'0')}}
                          </span><br>
                          <span v-if="itemSelected.customer">
                            {{this.itemSelected.customer.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row>
                    <b-col md="12">     
                      <b-link v-if="tableSub.items.length" 
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                     
                      <b-table  class="mb-0 table-budgets-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         

                        <template v-slot:cell(quantity)="row"> 
                          {{row.item.quantity}}
                          <span v-if="row.item.unit_measurement">{{row.item.unit_measurement.reference}}</span>
                        </template>

                        <template v-slot:cell(product)="row">
                          <div v-html="getDetailName(row.item)"></div>
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-col>                    
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>    

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_budgets">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterBudgets()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-budgets-wizard"
                      ref="wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="budgetValidDetailGeneral">          
            <b-row>
              <b-col md="6">
                <b-form-group label="Contacto" description="Nombre de la persona que solicitó el presupuesto">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.contact"
                                required
                                placeholder="Ingresar un nombre de contacto"
                                ref="input_contact">
                  </b-form-input>
                </b-form-group>                
              </b-col>
              <b-col md="6">
                <b-form-checkbox                   
                  v-model="crud.form.recurrent"
                  switch 
                  size="sm" 
                  class="pull-right mt-4">
                  <b>¿Es Recurrente?</b> <i>(Mensual)</i>
                </b-form-checkbox>                
              </b-col>
            </b-row>
            <b-row>                    
              <b-col md="4">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customers_id"
                            :where="conditionCustomersActive"
                            :disabled="crud.form.id>0"
                            ref="searchCustomer" 
                            title="Buscador (F2)" />                 
              </b-col>                  
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
              <b-col md="4">
                <b-form-group label="Fecha Vencimiento">
                  <b-form-datepicker size="sm" v-model="crud.form.date_expiration" placeholder="Fecha Vencimiento" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
            </b-row>              
            <b-row>  
              <b-col md="4">
                <FindObject render="search"
                            type="erpPointsSales" 
                            display="full_identification"
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.points_sales_id"
                            :key="crud.form.points_sales_id" 
                            :disabled="crud.form.id>0" />                 
              </b-col>                                           
              <b-col md="4">
                <FindObject render="search"
                            type="erpMethodsPayment" 
                            @select-object="loadMethodsPayment($event)" 
                            :valueID="crud.form.methods_payment_id"
                            :key="crud.form.methods_payment_id" />                 
              </b-col>              
              <b-col md="4">
                <FindObject render="search"
                            type="priceList" 
                            @select-object="loadPriceList($event)" 
                            :valueID="crud.form.price_list_id"
                            :key="crud.form.price_list_id" 
                            :where="conditionPriceListActive"
                            :disabled="crud.form.id>0" />                 
              </b-col>               
            </b-row>
          </tab-content>

          <tab-content title="Detalle" icon="fa fa-list" :before-change="budgetValidDetailItems">          
            <b-row>
              <b-col lg="12" md="12" sm="12">                
                  <Detail :customer_id="crud.form.customers_id" 
                          :price_list_id="crud.form.price_list_id"                   
                          @load-detail-products="setDetailItems($event, 'products')" 
                          @load-detail-products-compound="setDetailItems($event, 'productsCompound')" 
                          @load-detail-services="setDetailItems($event, 'services')" 
                          @load-detail-concepts="setDetailItems($event, 'concepts')" 
                          :detailProducts="crud.form.detailProducts"
                          :detailProductsCompound="crud.form.detailProductsCompound"
                          :detailServices="crud.form.detailServices"
                          :detailConcepts="crud.form.detailConcepts"
                          :hideConcepts="false"
                          :validItemDuplicatedProduct="!parameters.multipleItems"
                          :validItemDuplicatedConcept="!parameters.multipleItems"/>                           

                  <b-row class="mt-3 p-3" align-v="end">
                    <b-col md="2">
                      <b-row>
                        <b-col md="5" class="crud-budgets-totales-items-header">
                          ITEMS
                        </b-col>
                        <b-col md="7" class="crud-budgets-counts-items">
                          <b>{{this.getCantItemBudget}}</b>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6">
                      <div class="text-center mt-2">
                        <b-link href="#" @click="showSurchargeMethodPayment()">                          
                          Otros Métodos de Pago
                        </b-link>
                      </div>                         
                    </b-col>
                    <b-col md="4" v-if="getCurrencyBudget">
                      <b-row>
                        <b-col md="6" class="crud-budgets-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-budgets-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyBudget}).format(totals.subtotal)}}
                        </b-col>                    
                      </b-row>
                      <b-row>
                        <b-col md="6" class="crud-budgets-totales-items-header crud-budgets-totales-discounts-items">
                          DESCUENTO (%)
                        </b-col>                     
                        <b-col md="6" class="crud-budgets-totales-items">
                          <b-row>
                            <b-col class="p-0">
                              <b-form-input v-model="crud.form.percentage_discount" 
                                    type="number" 
                                    min="0" 
                                    max="100"
                                    step="1" 
                                    class="form-control"
                                    size="sm"
                                    @change="validDiscounts()"/>                                                  
                            </b-col>
                            <b-col class="crud-budgets-totales-discounts-items">
                              {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyBudget}).format(totals.discount)}}
                            </b-col>
                          </b-row>
                        </b-col>                    
                      </b-row>   
                      <b-row>
                        <b-col md="6" class="crud-budgets-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-budgets-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyBudget}).format(totals.subtotal_discount)}}
                        </b-col>                    
                      </b-row>                                            
                      <b-row>
                        <b-col md="6" class="crud-budgets-totales-items-header">
                          IVA
                        </b-col>
                        <b-col md="6" class="crud-budgets-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyBudget}).format(totals.iva)}}
                        </b-col>                    
                      </b-row>            
                      <b-row>
                        <b-col md="6" class="crud-budgets-totales-items-header">
                          TOTAL
                        </b-col>
                        <b-col md="6" class="crud-budgets-totales-items-amount">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyBudget}).format(totals.total)}}</b>
                        </b-col>                    
                      </b-row>   
                      <b-row v-if="crud.form.methods_payment && parseFloat(crud.form.methods_payment.surcharge) > 0">
                        <b-col md="6" class="crud-budgets-totales-items-header">
                          {{this.crud.form.methods_payment.name}} ({{ parseFloat(this.crud.form.methods_payment.surcharge) }})%
                        </b-col>
                        <b-col md="6" class="crud-budgets-totales-items-amount" style="color: blue;">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyBudget}).format(totals.total_surcharge)}}</b>
                        </b-col>                    
                      </b-row>                                                                                     
                    </b-col>
                  </b-row>
                  
              </b-col>        
            </b-row>
          </tab-content>

          <tab-content title="Observaciones" icon="fa fa-flag-checkered">          
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                    lazy
                  ></b-form-textarea>
                </b-form-group>
              </b-col>   
              <b-col md="12">
                <hr>
              </b-col>
              <b-col md="12">
                <b-form-group label="Título" description="Este título se utiliza en la impresión del presupuesto">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.title"
                                required
                                placeholder="Ingresar un título">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Observaciones Internas" description="Estas observaciones no se muestran en la impresión">
                  <b-form-textarea                
                    v-model="crud.form.observations_internal"
                    placeholder="Ingresar las observaciones internas..."
                    rows="3"
                    max-rows="6"
                    lazy
                  ></b-form-textarea>
                </b-form-group>
              </b-col>                      
            </b-row>
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelected">
                  <tbody>
                    <tr>
                      <td class="crud-budgets-title-table-custom align-middle">ID</td>
                      <td class="crud-budgets-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr>                                         
                    <tr>
                      <td class="crud-budgets-title-table-custom align-middle">Empresa</td>
                      <td class="crud-budgets-value-table-custom align-middle">
                        {{this.itemSelected.point_sale.business.reference}}
                      </td>
                    </tr>
                    <tr v-if="itemSelected.type_voucher && itemSelected.point_sale">
                      <td class="crud-budgets-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-budgets-value-table-custom align-middle">
                        <b>{{this.itemSelected.type_voucher.name + ' ' + this.itemSelected.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                           
                    <tr v-if="itemSelected.staff || itemSelected.seller">
                      <td class="crud-budgets-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-budgets-value-table-custom align-middle">
                        <div v-if="itemSelected.seller">
                          {{itemSelected.seller.name}}  
                        </div>
                        <div v-else>
                          {{itemSelected.staff.name}}  
                        </div>                        
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-budgets-title-table-custom align-middle">Fecha</td>
                      <td class="crud-budgets-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr>
                      <td class="crud-budgets-title-table-custom align-middle">Fecha Vencimiento</td>
                      <td class="crud-budgets-value-table-custom align-middle">
                        {{moment(this.itemSelected.date_expiration).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.point_sale">
                      <td class="crud-budgets-title-table-custom align-middle">Punto de Venta</td>
                      <td class="crud-budgets-value-table-custom align-middle">
                        {{this.itemSelected.point_sale.point_sale}} - {{this.itemSelected.point_sale.name}}                          
                      </td>
                    </tr>                                           
                    <tr v-if="itemSelected.customer">
                      <td class="crud-budgets-title-table-custom align-middle">Cliente</td>
                      <td class="crud-budgets-value-table-custom align-middle">
                        {{this.itemSelected.customer.name}}                        
                      </td>
                    </tr>  
                    <tr v-if="itemSelected.contact">
                      <td class="crud-budgets-title-table-custom align-middle">Contacto</td>
                      <td class="crud-budgets-value-table-custom align-middle">
                        {{this.itemSelected.contact}}                        
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.method_payment">
                      <td class="crud-budgets-title-table-custom align-middle">Método de Pago</td>
                      <td class="crud-budgets-value-table-custom align-middle">
                        {{this.itemSelected.method_payment.name}}        
                        <span v-if="parseFloat(itemSelected.method_payment.surcharge)>0">
                          ({{ parseFloat(itemSelected.method_payment.surcharge) }}%)
                        </span>                                        
                      </td>
                    </tr>                                
                    <tr>
                      <td class="crud-budgets-title-table-custom align-middle">Estado</td>
                      <td class="crud-budgets-value-table-custom align-middle">                        
                        <b-badge variant="primary" v-if="itemSelected.status=='open'">ABIERTO</b-badge>
                        <b-badge variant="success" v-if="itemSelected.status=='won'">GANADO</b-badge>
                        <b-badge variant="danger" v-if="itemSelected.status=='lost'">PERDIDO</b-badge>
                      </td>
                    </tr>  
                    <tr v-if="itemSelected.currency">
                      <td class="crud-budgets-title-table-custom align-middle">Moneda</td>
                      <td class="crud-budgets-value-table-custom align-middle">                        
                        {{itemSelected.currency.name}}
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.currency && itemSelected.amount_discount > 0">
                      <td class="crud-budgets-title-table-custom align-middle">Neto</td>
                      <td class="crud-budgets-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(parseFloat(this.itemSelected.amount_net) + parseFloat(this.itemSelected.amount_discount))}}                        
                      </td>
                    </tr>                           
                    <tr v-if="itemSelected.currency && itemSelected.amount_discount > 0">
                      <td class="crud-budgets-title-table-custom align-middle">Descuento ({{itemSelected.percentage_discount}} %)</td>
                      <td class="crud-budgets-value-table-custom align-middle text-danger">                                    
                        ({{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_discount)}})
                      </td>
                    </tr>                                                                       
                    <tr v-if="itemSelected.currency">
                      <td class="crud-budgets-title-table-custom align-middle">SubTotal</td>
                      <td class="crud-budgets-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_net)}}                        
                      </td>
                    </tr>                                         
                    <tr v-if="itemSelected.currency">
                      <td class="crud-budgets-title-table-custom align-middle">IVA</td>
                      <td class="crud-budgets-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_iva)}}                        
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.currency">
                      <td class="crud-budgets-title-table-custom align-middle">Total</td>
                      <td class="crud-budgets-value-table-custom align-middle">                        
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_total)}}</b>                        
                      </td>
                    </tr>                                             
                    <tr v-if="itemSelected.recurrent">
                      <td class="crud-budgets-title-table-custom align-middle">Recurrente</td>
                      <td class="crud-budgets-value-table-custom align-middle">                        
                        <b><i>Mensual</i></b>
                      </td>                      
                    </tr>                    
                    <tr v-if="itemSelected.observations">
                      <td class="crud-budgets-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-budgets-value-table-custom align-middle">                        
                        {{this.itemSelected.observations}}
                      </td>
                    </tr>       
                    <tr v-if="itemSelected.observations_internal">
                      <td class="crud-budgets-title-table-custom align-middle">Observaciones Internas</td>
                      <td class="crud-budgets-value-table-custom align-middle">                        
                        <div v-html="itemSelected.observations_internal"></div>
                      </td>
                    </tr>       
                    <tr v-if="itemSelected.title">
                      <td class="crud-budgets-title-table-custom align-middle">Titulo (Presupuesto)</td>
                      <td class="crud-budgets-value-table-custom align-middle">                        
                        <div v-html="itemSelected.title"></div>
                      </td>
                    </tr>                                                                                                           
                    <tr v-if="itemSelected.sale && itemSelected.sale.type_voucher && itemSelected.sale.point_sale">
                      <td class="crud-budgets-title-table-custom align-middle">Factura</td>                      
                      <td class="crud-budgets-value-table-custom align-middle">
                        <b>{{this.itemSelected.sale.type_voucher.name + ' ' + this.itemSelected.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.sale.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                          
                  </tbody>
                </table>
              </b-tab>              
              <b-tab title="Items">                
                <b-table class="mb-0 table-full-detail-budgets"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetail.items"
                        :fields="tableDetail.fields"
                        v-if="tableDetail.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(code)="data">                           
                    <div v-html="getDetailCode(data.item)"></div>
                  </template>

                  <template v-slot:cell(product)="data">                           
                    <div v-html="getDetailName(data.item)"></div>
                  </template>

                  <template v-slot:cell(list)="data">                                                                   
                    <span v-if="data.item.price_list">
                      {{data.item.price_list.reference}}
                    </span>
                    <span v-else>
                      <span v-if="itemSelected.price_list">
                        {{itemSelected.price_list.reference}}
                      </span>
                    </span>
                  </template>

                  <template v-slot:cell(quantity)="data">   
                    {{data.item.quantity}}                        
                    <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                  </template>

                  <template v-slot:cell(unit_price)="data">                          
                    <div v-if="itemSelected.currency">                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.price_surcharge)}}                      
                    </div>                    
                  </template>                  

                  <template v-slot:cell(amount_net)="data">                          
                    <div v-if="itemSelected.currency">                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_net)}}                      
                    </div>                    
                  </template>  

                  <template v-slot:cell(subtotal)="data">                          
                    <div v-if="itemSelected.currency">                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(parseFloat(data.item.quantity) * parseFloat(data.item.price_surcharge))}}                      
                    </div>                    
                  </template>                  

                  <template v-slot:cell(amount_iva)="data">                          
                    <div v-if="itemSelected.currency">                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_iva)}}                      
                    </div>                    
                  </template>                  

                  <template v-slot:cell(amount_discount)="data">                          
                    <div v-if="itemSelected.currency" :class="{'text-danger':parseFloat(data.item.percentage_discount)>0}">  
                      {{parseFloat(data.item.percentage_discount).toFixed(0)}}%
                      ({{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_discount)}})
                    </div>                    
                  </template>                  
                  
                  <template v-slot:cell(amount_total)="data">                           
                    <div v-if="itemSelected.currency">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_total)}}
                    </div>                    
                  </template>                  
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>

          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

      <!-- CRUD TEMPLATE -->
      <b-modal v-model="modal.template.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close         
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.template.title}}
        </div>

        <b-row>
          <b-col lg="12">            
            <div v-if="modal.template.budget && modal.template.budget.customer">
              <b>{{this.modal.template.budget.customer.name}}</b>
              <br>
              <div v-if="modal.template.budget.type_voucher && modal.template.budget.point_sale">
                {{modal.template.budget.type_voucher.name}} {{modal.template.budget.point_sale.point_sale.toString().padStart(4,'0') + '-' + modal.template.budget.number.toString().padStart(8,'0')}}
              </div>                            
            </div>
          </b-col>          

          <b-col lg="12">
            <hr>
          </b-col>        

          <b-col lg="12">
            <b-card no-body>
              <b-tabs pills card vertical class="budget-template">
                <b-tab v-for="item in modal.template.tabs" 
                       :key="'dyn-tab-' + item.id" 
                       :title="item.reference">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Referencia">
                        <b-form-input type="text"
                                      size="sm"
                                      v-model="item.reference"
                                      required
                                      placeholder="Ingresar una referencia">
                        </b-form-input>
                      </b-form-group>
                    </b-col>  
                    <b-col md="6">
                      <b-button size="sm" variant="danger" class="float-right" @click="closeTemplateTab(item)">
                        <b-icon icon="trash"></b-icon> Quitar
                      </b-button>                      
                    </b-col>    
                  </b-row>
                  <b-row>                                      
                    <b-col md="12">
                      <b-form-group label="Título">
                        <b-form-input type="text"
                                      size="sm"
                                      v-model="item.title"
                                      required
                                      placeholder="Ingresar un título">
                        </b-form-input>
                      </b-form-group>
                    </b-col>    

                    <b-col md="12">
                      <b-form-group label="Observaciones">
                        <vue-editor class="display-template-ql-editor" 
                                    v-model="item.description">
                        </vue-editor>              
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>      
                
                <template #tabs-end>
                  <b-nav-item role="presentation" @click.prevent="newTemplateTab" href="#" title="Agregar nuevo bloque">
                    <b>+</b>
                  </b-nav-item>
                </template>   
                
                <template #empty>
                  <div class="text-center text-muted">
                    No hay bloques creados<br>
                    Crea uno nuevo desde el botón <b>+</b> de la barra de navegación.
                  </div>
                </template>                                   
              </b-tabs>
            </b-card>            
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.template.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveTemplate()">Guardar</b-button>          
        </div>
      </b-modal>  

      <!-- CRUD GANADO O PERDIDO -->
      <b-modal v-model="modal.status.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.status.title}}
        </div>
        
        <b-row>      
          <b-col lg="12">            
            <div v-if="modal.status.budget && modal.status.budget.customer">
              <b>{{this.modal.status.budget.customer.name}}</b>
              <br>
              <div v-if="modal.status.budget.type_voucher && modal.status.budget.point_sale">
                {{modal.status.budget.type_voucher.name}} {{modal.status.budget.point_sale.point_sale.toString().padStart(4,'0') + '-' + modal.status.budget.number.toString().padStart(8,'0')}}
              </div>                            
            </div>
          </b-col>  

          <b-col lg="12">
            <hr>
          </b-col>  

          <b-col md="12">
            <b-form-group label="Fecha">
              <b-form-datepicker size="sm" v-model="crud.formStatus.date_status" placeholder="Fecha" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>

          <b-col md="12">
            <b-button-group style="width: 100%;" class="mb-1">
              <b-button variant="primary" @click="crud.formStatus.status='open'">Abierto</b-button>
            </b-button-group>
            <b-button-group style="width: 100%;">
              <b-button variant="success" @click="crud.formStatus.status='won'">Ganado</b-button>
              <b-button variant="danger" @click="crud.formStatus.status='lost'">Perdido</b-button>            
            </b-button-group>
          </b-col> 

          <b-col md="12" v-if="crud.formStatus.status=='open'" class="mt-3">
            <div class="crud-budget-modal-customer-open">
              <i class="fa fa-hand-paper-o"></i> Presupuesto Abierto
            </div>
          </b-col>

          <b-col md="12" v-if="crud.formStatus.status=='won'" class="mt-3">
            <div class="crud-budget-modal-customer-won">
              <i class="fa fa-thumbs-up"></i> Presupuesto Ganado!!
            </div>
          </b-col>
          
          <b-col md="12" v-if="crud.formStatus.status=='lost'" class="mt-3">
            <div class="crud-budget-modal-customer-lost">
              <i class="fa fa-thumbs-down"></i> Presupuesto Perdido                  
            </div>
          </b-col>            
          <b-col md="12" v-if="crud.formStatus.status=='lost'" class="mt-3">
            <b-form-group label="Observaciones Internas" description="Estas observaciones no se muestran en la impresión">
              <b-form-textarea                
                v-model="crud.formStatus.observations_internal"
                placeholder="Ingresar las observaciones internas..."
                rows="3"
                max-rows="4"
                lazy
              ></b-form-textarea>
            </b-form-group>            
          </b-col>          
        </b-row>                    
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.status.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveStatus()">Guardar</b-button>          
        </div>
      </b-modal>   

      <!-- METODOS DE PAGO -->          
      <b-modal v-model="modal.method_payment.active"
              no-close-on-backdrop
              hide-header-close         
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.method_payment.title}}
        </div>

        <b-row>
          <b-col lg="12">            
            <MethodPaymentSurcharge v-if="crud.form.price_list && crud.form.price_list.currency" 
                                    :amount="totals.total" 
                                    :currency="crud.form.price_list.currency.code"
                                    @setMethodPayment="setMethodPayment($event)"/>          
                                    
            <MethodPaymentSurcharge v-else  
                                    :amount="totals.total" 
                                    currency="ARS"
                                    @setMethodPayment="setMethodPayment($event)"/>          
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.method_payment.active=false">Cancelar</b-button>          
        </div>        
      </b-modal>           
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Printer from '@/components/inc/printer/printer'
  import Detail from '@/components/inc/cpbtDetail/detail'
  import moment from 'moment'
  import Param from '@/config/parameters'  
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { VueEditor } from "vue2-editor";
  import Storage from '@/handler/storageSession'
  import MethodPaymentSurcharge from './inc/methodPayment'
  import Minimize from '@/components/inc/modal/minimize'

  export default {
    components: {
      FindObject,
      Printer,
      Detail,
      FormWizard,
      TabContent,     
      VueEditor,
      MethodPaymentSurcharge,   
      Minimize,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.PRESUPUESTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'budgets',
          elements: {
            deleteBudgets: true             
          }
        },
        parameters: {          
          multipleItems: Helper.hasParametersAccess(Param.P51),
        },         
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },     
        tableSub : {
          items: [],
          fields: []
        },            
        tableDetail : {
          items: [],
          fields: []
        },            
        crud: {
          form: {
            id: 0,
            date: '',
            date_expiration: '',              
            types_vouchers_id: 10,
            types_vouchers: null,
            letter: '',
            number: 0,
            methods_payment_id: 0,
            methods_payment: null,
            price_list_id: 0,
            price_list: null,            
            points_sales_id: 0,
            points_sales: null,              
            observations: '',            
            observations_internal: '',
            contact: '',
            recurrent: false,
            title: 'Presupuesto',
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,   
            percentage_discount: 0,        
            detailProducts: [],
            detailProductsCompound: [],
            detailServices: [],
            detailConcepts: [],
          },            
          print: {
            reference: 'budget',
            id: 0,
            key: 0,
          },
          formStatus: {
            id: 0,
            status: 'open',
            date_status: '',
            observations_internal: '',
          }               
        },
        totals: {
          subtotal: 0,
          discount: 0,
          iva: 0,
          total: 0,
          total_surcharge: 0,
        },
        modal: {
          form: {
            active: false,
            title: '',
          },
          detail: {
            active: false, 
            title: '',
          }, 
          template: {
            budget: null,                        
            tabs: [],
            tabCounter: 0,
            active: false,
            title: ''           
          }, 
          status: {
            budget: null,                        
            active: false,
            title: '',            
          },
          method_payment: {
            active: false,
            title: ''                        
          },                               
        },          
        arr: {
          budgets: [],   
          export: [],
          filters : {
            customers: [],
            sellers: [],
            points_sales: [],
            status: [
              {code: 'open', label: 'Abierto'},
              {code: 'won', label: 'Ganado'},
              {code: 'lost', label: 'Perdido'},
            ],
            invoiced: [
              {code: true, label: 'Facturado'},
              {code: false, label: 'No Facturado'}
            ],
            recurrent: [
              {code: true, label: 'Recurrente'},
              {code: false, label: 'No Recurrente'}
            ],            
          },                       
        },           
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          points_sales: null,
          invoiced: null,
          number: '',
          status: [{code: 'open', label: 'Abierto'}],
          recurrent: null,
        },     
        itemSelected: null,             
      }
    },
    created () {     
        /* Configurar permisos de vistas y elementos */
        Helper.hasAccessView(this.access)
        this.access.elements.deleteBudgets = Helper.hasAccessElement(this.access, 'delete_budgets')
        /* Fin configuracion */

        window.addEventListener("keydown", this.handlerAccessDirect);          
    },    
    destroyed() {
      window.removeEventListener("keydown", this.handlerAccessDirect);          
    },      
    mounted() {   
      this.loadFieldTable()
      this.filterLoad()   
      this.filterBudgets()              
      this.loadStyleConfig()
    },    
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },
      moduleSalesActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENTAS) {                             
            status = true                       
          }
        })        
        return status
      },

      // OTHERS
      subtotalItemSelected() {
        if(this.itemSelected) {
          return parseFloat(this.itemSelected.amount_total)
        } else {
          return 0
        }
      },

      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },      
      conditionPriceListActive(){
        return [{field: 'active', condition: true}];
      },      
      
      // CANT / CURRENCY      
      getCantItemBudget() {
        var cant1 = this.crud.form.detailProducts.length
        var cant2 = this.crud.form.detailProductsCompound.length
        var cant3 = this.crud.form.detailServices.length
        var cant4 = this.crud.form.detailConcepts.length

        return parseFloat(cant1 + cant2 + cant3 + cant4)
      },
      getCurrencyBudget() {        
        var currency = 'ARS'
        if(this.crud.form.price_list) {                    
          if(this.crud.form.price_list.currency) {                   
            currency = this.crud.form.price_list.currency.code            
          }
        }         
        return currency
      }   
    },
    watch: {        
      'crud.form.customers_id': function (newQuestion, oldQuestion) {         
        this.searchVoucher()
      },
      'crud.form.points_sales_id': function (newQuestion, oldQuestion) {                
        this.searchVoucher()
      },
      'crud.form.detailProducts': function (newQuestion, oldQuestion) { 
        this.searchTotalsTemporary()
      },
      'crud.form.detailProductsCompound': function (newQuestion, oldQuestion) {       
        this.searchTotalsTemporary()
      },      
      'crud.form.detailServices': function (newQuestion, oldQuestion) { 
        this.searchTotalsTemporary()
      },
      'crud.form.detailConcepts': function (newQuestion, oldQuestion) { 
        this.searchTotalsTemporary()
      }      
    },    
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_budgets = document.getElementById('view_list_budgets')
        var view_footer_budgets = document.getElementById('view_footer_budgets')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_budgets.classList.remove('pr-0')
          view_footer_budgets.classList.remove('pr-0')
        } else {          
          view_list_budgets.classList.add('pr-0')
          view_footer_budgets.classList.add('pr-0')
        }        
      },
      loadFieldTable () {
        // TABLE BUDGETS
        this.table.fields.push({key: 'business', label: 'Empresa', class:"align-middle text-left", width:"60px"})
        this.table.fields.push({key: 'id', label: 'Nº', class:"align-middle text-center", width:"120px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"80px"})
        this.table.fields.push({key: 'date_expiration', label: 'Vto', class:"align-middle text-center", width:"80px"})
        this.table.fields.push({key: 'customers_id', label: 'Cliente', class:"align-middle", width:"200px"})                      

        if(this.moduleSellersActive){
          this.table.fields.push({key: 'sellers_id', label: 'Vendedor', class:"align-middle text-center", width:"60px"})
        }

        this.table.fields.push({key: 'staff_id', label: 'Staff', class:"align-middle text-center", width:"60px"})
        this.table.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"90px"})                      
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"15px"})
        this.table.fields.push({key: 'observations_internal', label: '', class:"align-middle text-center", width:"15px"})                                                
        this.table.fields.push({key: 'invoice', label: '', class:"align-middle text-center", width:"15px"})                        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE DETALLE
        this.tableSub.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle", width:"50px"})                
        this.tableSub.fields.push({key: 'product', label: 'Item', class:"align-middle", width:"150px"})                

        // TABLE DETALLE COMPLETO        
        this.tableDetail.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"50px"})                
        this.tableDetail.fields.push({key: 'product', label: 'Item', class:"align-middle text-truncate", width:"200px"})                
        this.tableDetail.fields.push({key: 'list', label: 'Lista', class:"align-middle text-truncate", width:"50px"})                
        this.tableDetail.fields.push({key: 'quantity', label: 'Cant', class:"align-middle text-right", width:"60px"})                
        this.tableDetail.fields.push({key: 'unit_price', label: 'P.Unit', class:"align-middle text-right", width:"70px"})                
        this.tableDetail.fields.push({key: 'subtotal', label: 'Subtotal', class:"align-middle text-right", width:"70px"})                
        this.tableDetail.fields.push({key: 'amount_discount', label: 'Desc', class:"align-middle text-right", width:"70px"})                
        this.tableDetail.fields.push({key: 'amount_net', label: 'P.Neto', class:"align-middle text-right", width:"70px"})                       
        this.tableDetail.fields.push({key: 'amount_iva', label: 'IVA', class:"align-middle text-right", width:"70px"})                
        this.tableDetail.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"70px"})                        
      },      
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.budgets.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
      setConfigTableBudgetsShow() {
        var arrTable = document.getElementsByClassName('table-budgets-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {          
          arrTable[i].style.minHeight = '280px'
        }        
      },
      setConfigTableBudgetsHide() {
        var arrTable = document.getElementsByClassName('table-budgets-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-budgets-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-budgets-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },        

      // RENDER
      showGenerateInvoice(item) {
        var status = false           
        if(!item.sale_id && !item.order_id && this.moduleSalesActive) {
          status = true
        }             
        return status
      },      

      // SELECT
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id   
          
          if(object.data_fiscal) {
            if(object.data_fiscal.points_sales_id) {
              if(!this.crud.form.points_sales_id) {
                this.crud.form.points_sales = object.data_fiscal.points_sales
                this.crud.form.points_sales_id = object.data_fiscal.points_sales_id
              }
            }

            if(object.data_fiscal.methods_payment_id) {
              if(!this.crud.form.methods_payment_id) {
                this.crud.form.methods_payment = object.data_fiscal.methods_payment
                this.crud.form.methods_payment_id = object.data_fiscal.methods_payment_id   

                let days = parseInt(object.data_fiscal.methods_payment.term)
                this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')                                         
              }
            }
          }
          
          if(!this.crud.form.price_list_id) {
            if(object.price_list) {
              if(object.price_list.list) {              
                this.crud.form.price_list = object.price_list.list
                this.crud.form.price_list_id = object.price_list.list.id
              }
            }          
          }
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0
        }
      }, 
      loadPointsSales (object) {                
        if(object){
          this.crud.form.points_sales = object
          this.crud.form.points_sales_id = object.id             
        } else {
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0
        }
      },      
      loadMethodsPayment (object) {
        if(object){
          this.crud.form.methods_payment = object
          this.crud.form.methods_payment_id = object.id 
        } else {
          this.crud.form.methods_payment = null
          this.crud.form.methods_payment_id = 0
        }
      },
      loadPriceList (object) {
        if(object){
          this.crud.form.price_list = object
          this.crud.form.price_list_id = object.id             
        } else {
          this.crud.form.price_list = null
          this.crud.form.price_list_id = 0
        }
      },      
      prepareExport(data) {        
        this.arr.export = []
        data.forEach(element => {
          var businessID = 0
          var businessName = ""
          var customersID = 0
          var customersCode = ""
          var customersName = ""        
          var methodsPaymentID = 0
          var methodsPaymentName = ""
          var voucherReference = ""
          var currencyID = 0
          var currencyName = ""    

          if(element.point_sale && element.point_sale.business) {
            businessID = element.point_sale.business.id            
            businessName = element.point_sale.business.reference
          }
          if(element.customer) {
            customersID = element.customer.id
            customersCode = element.customer.code
            customersName = element.customer.name            
          }
          if(element.method_payment) {
            methodsPaymentID = element.method_payment.id
            methodsPaymentName = element.method_payment.name
          }
          if(element.currency) {
            currencyID = element.currency.id
            currencyName = element.currency.name
          }                   
          if(element.type_voucher) {            
            voucherReference = element.type_voucher.name + ' ' + element.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.number.toString().padStart(8,'0')
          }          

          this.arr.export.push({
            id: element.id,
            businessID: businessID,
            businessName: businessName,
            date: element.date,                      
            date_expiration: element.date_expiration,                      
            voucherReference: voucherReference,              
            customersID: customersID,
            customersCode: customersCode,
            customersName: customersName,
            methodsPaymentID: methodsPaymentID,
            methodsPaymentName: methodsPaymentName,
            currencyID: currencyID,
            currencyName: currencyName,    
            percentage: element.percentage_discount.replace('.',','),
            discount: element.amount_discount.replace('.',','),
            iva: element.amount_iva.replace('.',','),                               
            total: element.amount_total.replace('.',','),                        
            status: element.status,
            recurrent: element.recurrent,
            contact: element.contact,
            observations: element.observations,     
            title: element.title,
            observations_internal: element.observations_internal,                   
            productID: 0,
            productCode: "",
            productName: "",
            serviceID: 0,
            serviceCode: "",
            serviceName: "",
            conceptID: 0,
            conceptName: "",   
            productCompoundID: 0,
            productCompoundName: "",  
            priceListID: 0,
            priceListName: "",                                                    
            quantity: 0,
            unitMeasurementID: 0,
            unitMeasurementName: "",            
            unit_price: 0,
            surcharge: 0,
            unit_price_surcharge: 0,            
            amount_net: 0,
            amount_discount: 0,
            amount_iva: 0,                          
            amount_total: 0,
          })

          element.details.forEach(element1 => {
            var productID = 0
            var productCode = ""
            var productName = ""
            var serviceID = 0
            var serviceCode = ""
            var serviceName = ""            
            var conceptID = 0
            var conceptName = ""     
            var productCompoundID = 0
            var productCompoundName = ""     
            var unitMeasurementID = 0
            var unitMeasurementName = ""               
            var priceListID = 0
            var priceListName = ""   

            if(element1.products_id && element1.product) {
              productID = element1.product.id
              productCode = element1.product.code
              productName = element1.product.name            
            }

            if(element1.products_colors_id && element1.products_color) {
              productID = element1.products_color.id
              productCode = element1.products_color.code
              productName = element1.products_color.name + " (" + element1.products_color.color.name + ")"
            }            

            if(element1.products_waist_id && element1.products_waist) {
              productID = element1.products_waist.id
              productCode = element1.products_waist.code
              productName = element1.products_waist.name + " (" + element1.products_waist.waist.name + ")"
            }            

            if(element1.products_color_waist_id && element1.products_color_waist) {
              productID = element1.products_color_waist.id
              productCode = element1.products_color_waist.code
              productName = element1.products_color_waist.name + " (" + element1.products_color_waist.color.name + " - " + element1.products_color_waist.waist.name + ")"
            }       

            if(element1.services_id && element1.service) {
              serviceID = element1.service.id
              serviceCode = element1.service.code
              serviceName = element1.service.name            
            }

            if(element1.concepts_id && element1.concept) {
              conceptID = element1.concept.id
              conceptName = element1.concept.name            
            }

            if(element1.products_compound_id && element1.products_compound) {
              productCompoundID = element1.products_compound.id
              productCompoundName = element1.products_compound.name            
            }    

            if(element1.unit_measurement_id && element1.unit_measurement) {
              unitMeasurementID = element1.unit_measurement.id
              unitMeasurementName = element1.unit_measurement.name            
            }            

            if(element1.price_list_id && element1.price_list) {              
              priceListID = element1.price_list.id
              priceListName = element1.price_list.name            
            } else {
              if(element.price_list_id && element.price_list) {
                priceListID = element.price_list.id
                priceListName = element.price_list.name                          
              }
            }

            this.arr.export.push({
              productID: productID,
              productCode: productCode,
              productName: productName,
              serviceID: serviceID,
              serviceCode: serviceCode,
              serviceName: serviceName,
              conceptID: conceptID,
              conceptName: conceptName,   
              productCompoundID: productCompoundID,
              productCompoundName: productCompoundName,                                          
              priceListID: priceListID,
              priceListName: priceListName,              
              quantity: element1.quantity.replace('.',','),
              unitMeasurementID: unitMeasurementID,
              unitMeasurementName: unitMeasurementName,
              unit_price: element1.price_unit.replace('.',','),
              surcharge: element1.surcharge ? element1.surcharge.replace('.',',') : '0',
              unit_price_surcharge: element1.price_surcharge.toString().replace('.',','),              
              amount_net: element1.amount_net.replace('.',','),
              amount_discount: element1.amount_discount.replace('.',','),
              amount_iva: element1.amount_iva.replace('.',','),                          
              amount_total: element1.amount_total.replace('.',','),
            })
          })
        });
      },

      // ABM MAIN
      add() {
        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.date_expiration = moment().add(10,'d').format('YYYY-MM-DD')
        this.crud.form.observations = ''
        this.crud.form.observations_internal = ''
        this.crud.form.contact = ''
        this.crud.form.recurrent = false
        this.crud.form.title = 'Presupuesto'  
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.customers_id = 0
        this.crud.form.customers = null   
        this.crud.form.points_sales_id = 0
        this.crud.form.points_sales = null  
        this.crud.form.methods_payment_id = 0
        this.crud.form.methods_payment = null  
        this.crud.form.price_list_id = 0
        this.crud.form.price_list = null        
        this.crud.form.percentage_discount = 0             
        this.crud.form.detailProducts = []
        this.crud.form.detailProductsCompound = []
        this.crud.form.detailServices = []
        this.crud.form.detailConcepts = []

        setTimeout(()=>{
          if(this.$refs.input_contact) {
            this.$refs.input_contact.focus()
          }        
        }, 1000)
        
        this.modal.form.title = "Nuevo Presupuesto"
        this.modal.form.active = true
      },      
      edit(item) {        
        let loader = this.$loading.show();

        this.crud.form.id = item.id
        this.crud.form.date = moment(item.date).format('YYYY-MM-DD')
        this.crud.form.date_expiration = moment(item.date_expiration).format('YYYY-MM-DD')
        this.crud.form.observations = item.observations
        this.crud.form.observations_internal = item.observations_internal
        this.crud.form.contact = item.contact
        this.crud.form.recurrent = item.recurrent
        this.crud.form.title = item.title
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.customers_id = item.customers_id
        this.crud.form.customers = item.customer       
        this.crud.form.points_sales_id = item.points_sales_id
        this.crud.form.points_sales = item.point_sale
        this.crud.form.methods_payment_id = item.methods_payment_id
        this.crud.form.methods_payment = item.methods_payment
        this.crud.form.price_list_id = item.price_list_id
        this.crud.form.price_list = item.price_list
        this.crud.form.percentage_discount = item.percentage_discount
        this.crud.form.types_vouchers = item.type_voucher
        this.crud.form.types_vouchers_id = item.types_vouchers_id
        this.crud.form.letter = item.letter
        this.crud.form.number = item.number
        
        var result = serviceAPI.mostrarPresupuesto(item.id);

        result.then((response) => {  
          var data = response.data.detail

          if(data) {
            this.crud.form.detailProducts = []
            this.crud.form.detailProductsCompound = []
            this.crud.form.detailServices = []
            this.crud.form.detailConcepts = []
            
            data.forEach(element => {              
              if(element.product) {
                this.crud.form.detailProducts.push(element)
              }              
              if(element.productCompound) {
                this.crud.form.detailProductsCompound.push(element)
              }                            
              if(element.service) {
                this.crud.form.detailServices.push(element)
              }    
              if(element.concept) {
                this.crud.form.detailConcepts.push(element)
              }                                                        
            });      
          }          

          setTimeout(()=>{
            if(this.$refs.input_contact) {
              this.$refs.input_contact.focus()
            }        
          }, 1000)          

          this.modal.form.title = "Editar Presupuesto"
          this.modal.form.active = true          
          loader.hide()

          this.searchVoucher()
        })
      },      
      remove(item) {
        this.crud.form.id = item.id
        
        var voucher = "ID #" + item.id
        if(item.type_voucher && item.point_sale) {
          voucher = item.type_voucher.name + ' ' + item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')
        }        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el presupuesto ' + voucher + '?', {
          title: 'Borrar Presupuesto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarPresupuesto(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterBudgets()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del presupuesto?', {
          title: 'Generar Presupuesto',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            if (this.crud.form.id) {
              var result = serviceAPI.editarPresupuesto(this.crud.form);
            } else {
              var result = serviceAPI.agregarPresupuesto(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterBudgets()
              this.$awn.success("Presupuesto generado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },          
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el presupuesto?', {
          title: 'Cancelar el Presupuesto',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },
      setDetailItems(event, type) {         
        if(type=="products") {
          this.crud.form.detailProducts = event                
        } 
        if(type=="productsCompound") {          
          this.crud.form.detailProductsCompound = event                
        }         
        if(type=="services") {
          this.crud.form.detailServices = event                
        } 
        if(type=="concepts") {
          this.crud.form.detailConcepts = event                
        }                 
      },         
      budgetValidDetailGeneral() {
        this.searchTotalsTemporary()  

        if(this.crud.form.customers_id && this.crud.form.points_sales_id  && this.crud.form.methods_payment_id) {
          return true;
        } else {
          if(!this.crud.form.customers_id) {
            this.$awn.alert("No se cargó el cliente");
            return false;
          }
          if(!this.crud.form.points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }
          if(!this.crud.form.methods_payment_id) {
            this.$awn.alert("No se cargó el método de pago");
            return false;
          }
        }        
      },
      budgetValidDetailItems() {
          if( this.crud.form.detailProducts.length || 
              this.crud.form.detailProductsCompound.length || 
              this.crud.form.detailServices.length || 
              this.crud.form.detailConcepts.length) { 
            return true;
          } else {
            this.$awn.alert("No se cargó ningún item");
            return false;
          }
      },

      // SELECT VOUCHER 
      searchVoucher() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherById({
              'points_sales_id': this.crud.form.points_sales_id,
              'types_vouchers_id' : this.crud.form.types_vouchers_id,
            });
            result.then((response) => {                      
              this.crud.form.types_vouchers = response.data.type_voucher
              this.crud.form.letter = response.data.type_voucher.letter                    
              this.crud.form.number = response.data.number
              
              if( this.crud.form.types_vouchers ) {              
                this.modal.form.title = this.crud.form.types_vouchers.name
                this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
                this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')
              }
                          
            })           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {                          
            this.modal.form.title = this.crud.form.types_vouchers.name
            this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')
          }                   
        }
      },
      searchTotalsTemporary() {
        if(this.crud.form.detailProducts || this.crud.form.detailProductsCompound || this.crud.form.detailsServices || this.crud.form.detailsConcepts) {          
          var result = serviceAPI.obtenerVoucherTotalsTemporary({
            'header': this.crud.form,
            'detailsProducts': this.crud.form.detailProducts,   
            'detailsProductsCompound': this.crud.form.detailProductsCompound,   
            'detailsServices': this.crud.form.detailServices,   
            'detailsConcepts': this.crud.form.detailConcepts,   
            'methods_payment': this.crud.form.methods_payment,
          });
          result.then((response) => {     
            var data = response.data   

            this.totals.subtotal = data.amount_net
            this.totals.discount = data.amount_discount
            this.totals.subtotal_discount = data.amount_subtotal
            this.totals.iva = data.amount_iva
            this.totals.total = data.amount_total
            this.totals.total_surcharge = data.amount_total_surcharge
          })           
        }                   
      },
      validDiscounts() {
        if(this.crud.form.percentage_discount=='') {          
          this.crud.form.percentage_discount = 0
        } else {          
          if( parseFloat(this.crud.form.percentage_discount) < 0 || 
              parseFloat(this.crud.form.percentage_discount) > 100 ) {            
            this.crud.form.percentage_discount = 0
          } 
        }       
        this.searchTotalsTemporary()
      },

      // FILTER
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()   
        this.filterLoadPointSale()
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 

        this.restoreFilterStorage() 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {        
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                              
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.full_identification })            
          });          
        })   
      },    
      filterBudgets(forceOpenSub=false) {
        this.saveFilterStorage()

        this.table.isBusy = true
        var result = serviceAPI.filtrarPresupuesto(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.budgets = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            }         
            
            if(element.status == 'won') {
              element._rowVariant = 'success'
            } else if(element.status == 'lost') {
              element._rowVariant = 'danger'
            }
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })           
        .catch(error => {                   
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },    

      // BUDGET DETAIL
      openSub(item) {                
        this.itemSelected = item        
        
        this.tableSub.items = item.details           
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSub.items=[]
        this.itemSelected = null
      },               
      openDetail() {              
        this.tableDetail.items = this.itemSelected.details

        this.modal.detail.title = "Detalle del Presupuesto"
        this.modal.detail.active = true
      }, 
      getDetailCode(item) {        
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductCode(item)
        }
        if(item.services_id) {
          return item.service.code
        }        
        if(item.concepts_id) {
          return item.concept.code
        }                
        if(item.products_compound_id) {          
          return item.products_compound.code
        }                
      },
      getDetailName(item) {
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductName(item)
        }
        if(item.services_id) {
          return item.description
        }        
        if(item.concepts_id) {          
          return item.description
        }                
        if(item.products_compound_id) {          
          return item.description
        }                
      },       
      getProductCode(item) {
        var code = ''
        var prod = null

        if(item.code) {
          code = item.code
        }          

        if(item.products_id) {
          if(code) {
            return code
          } else {                      
            return item.product.code
          }
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(code) {
            return code
          } else {
            return prod.code
          }           
        }

        if(item.products_waist_id) {
          prod = item.products_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }
      },      
      getProductName(item) {        
        var name = ''
        var prod = null

        if(item.description) {
          name = item.description
        }          
        
        if(item.products_id) {
          if(name) {
            return name
          } else {
            return item.product.name
          }          
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(name) {
            return name + ' (' + prod.color.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ')'
          }                    
        }

        if(item.products_waist_id) {
          prod = item.products_waist           
          if(name) {
            return name + ' (' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.waist.name + ')'
          }
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(name) {
            return name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          }          
        }
      },  

      // PROCESS ADMINISTRATIVE      
      generateInvoice(item) {
        this.$router.push({ name: 'SalesStaffSalesGenerateByBudget', params: {budgetID: item.id} })
      },

      // PRINT
      sendPrinterBudget(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
      },  
      
      // STATUS
      openStatus(item) {
        this.modal.status.budget = item

        this.crud.formStatus.id = item.id        
        this.crud.formStatus.status = item.status
        this.crud.formStatus.date_status = moment().format('YYYY-MM-DD')
        this.crud.formStatus.observations_internal = item.observations_internal
        
        this.modal.status.active = true
        this.modal.status.title = "Cambiar Estado"
      },      
      saveStatus() {        
        let statusOld = this.modal.status.budget.status
        if(!this.crud.formStatus.status || this.crud.formStatus.status == statusOld) {
          this.$awn.info("Primero tenés que cambiar el estado del presupuesto para continuar");
          return false
        }

        this.$bvModal.msgBoxConfirm('¿Cambiar el estado del presupuesto?', {
          title: 'Cambio de Estado',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'CAMBIAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {                       
            let loader = this.$loading.show();            
                      
            var result = serviceAPI.cambiarStatusPresupuesto(this.crud.formStatus);
           
            result.then((response) => {
              this.modal.status.active = false
              loader.hide()
              this.filterBudgets()
              this.$awn.success("Estado cambiado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {              
          this.$awn.alert(Error.showError(error));
        })                
      },
      showStatusWon(item) {
        let status = false
        if(item.status == 'won') {
          status = true
        }
        return status
      },
      showStatusOpen(item) {
        let status = false
        if(item.status == 'open') {
          status = true
        }
        return status
      },      
      showStatusLost(item) {
        let status = false
        if(item.status == 'lost') {
          status = true
        }
        return status
      },      

      // PRESUPUESTO SERVICIO
      setTemplate(item){
        this.modal.template.budget = item

        this.modal.template.tabs = []
        this.modal.template.tabCounter = 0
        if(!item.template || !JSON.parse(item.template).length) {
          if(item.details) {
            item.details.forEach(element => {
              if(element.service.template_budget) {
                if(this.modal.template.tabCounter == 0) {
                  this.modal.template.tabs = JSON.parse(element.service.template_budget)
                  this.modal.template.tabCounter = this.modal.template.tabs.length              
                } else {
                  let tabsTMP = JSON.parse(element.service.template_budget)
                  let tabCounterTMP = tabsTMP.length

                  tabsTMP.forEach(element => {
                    this.modal.template.tabs.push(element)
                  });

                  this.modal.template.tabCounter = this.modal.template.tabCounter + tabCounterTMP
                }
              }
            });
          }          
        } else {
          this.modal.template.tabs = JSON.parse(item.template)
          this.modal.template.tabCounter = this.modal.template.tabs.length          
        }

        this.modal.template.active = true
        this.modal.template.title = "Plantilla"
      },  
      newTemplateTab() {
        this.modal.template.tabs.push({
          id: this.modal.template.tabCounter++,
          reference: "Bloque " + this.modal.template.tabCounter,
          title: '',
          description: '',
        })       
      },         
      closeTemplateTab(item) {
        this.$bvModal.msgBoxConfirm('¿Desea borrar el bloque ' + item.reference + '?', {
          title: 'Borrar Bloque',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            for (let i = 0; i < this.modal.template.tabs.length; i++) {
              if (this.modal.template.tabs[i].id === item.id) {
                this.modal.template.tabs.splice(i, 1)
              }
            }
          } 
        })
        .catch(error => {               
          this.$awn.alert(Error.showError(error));
        })            
      },   
      saveTemplate() {
        this.$bvModal.msgBoxConfirm('¿Guardar plantilla para este presupuesto?', {
          title: 'Plantilla',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {                       
            let loader = this.$loading.show();            

            var template = JSON.stringify(this.modal.template.tabs)
            
            var result = serviceAPI.editarTemplatePresupuesto({
              id: this.modal.template.budget.id,
              template: template,
            });
            
            result.then((response) => {
              this.modal.template.active = false
              loader.hide()
              this.filterBudgets()
              this.$awn.success("Plantilla guardada");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {    
          this.$awn.alert(Error.showError(error));
        })
      }, 

      // RECARGO METODO DE PAGO
      showSurchargeMethodPayment() {
        this.modal.method_payment.title = "Métodos de Pago Disponibles"
        this.modal.method_payment.active = true          
      },
      setMethodPayment(object) {      
        if(object){
          this.crud.form.methods_payment = object
          this.crud.form.methods_payment_id = object.id             

          let days = parseInt(object.term)
          this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')                          
        } 
                
        this.searchTotalsTemporary()  
        this.modal.method_payment.active=false
      },

      // ACCESSOS DIRECTOS
      handlerAccessDirect(e) {                

        // ctrl + shift + p = 80
        if (e.ctrlKey && e.shiftKey && e.keyCode === 80) {                   
          e.preventDefault();
          this.add()
        }         
        
        // ctrl + shift + enter = 13
        if (e.ctrlKey && e.shiftKey && e.keyCode === 13) {
          e.preventDefault(); 
          
          if(this.$refs.wizard) {            
            this.$refs.wizard.nextTab();            
          } 
        }     

        // f2 = 113
        if(this.modal.form.active) {          
          if (e.keyCode === 113) {           
            if(this.$refs.searchCustomer) {
              this.$refs.searchCustomer.openSearch()
            }
          }
        }                        
      },        
      
      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_crud_budget', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_crud_budget')) {
          this.filters = JSON.parse(Storage.getValue('filter_crud_budget'))
        }         
      },       
    }
  }
</script>
<style>
  .crud-budgets-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-budgets-code {
    color: gray;
    font-size: 12px;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-budgets-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-budgets-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-budgets-value-table-custom {
    padding-left: 5px;
  }  
  .table-full-detail-budgets {
    overflow: auto;
    max-height: 350px;    
  }
  .budgets-alert-retailer {
    font-weight: bold;
    color: gray;
    font-size: 12px;
  }
  .budgets-alert-noretailer {
    font-weight: bold;
    color: darkblue;
    font-size: 12px;
  } 
  .budget-total-table-paid {
    color: darkgreen;
    font-weight: 700;
  } 
  .budget-total-table-nopaid {
    color: darkred;
    font-weight: 700;
  }
  .crud-budgets-nro-internal {
    color: var(--mdc-theme-primary);
    font-size: 12px;
  }
  .crud-new-budgets-button-add {
    margin-top: 29px;
  }
  .crud-budgets-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-budgets-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }  
  .crud-budgets-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }  
  .crud-budgets-totales-items-amount {
    font-size: 15px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;    
    border-width: 1px;    
  }    
  .crud-budgets-wizard .wizard-header {
    padding: 0px;
  }  
  .crud-budgets-totales-discounts-items {
    padding-top: 5px;
  }
  .crud-budget-action-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }       
  .crud-budget-modal-customer-open {
    color: blue;
    font-size: 18px;
  }    
  .crud-budget-modal-customer-won {
    color: darkgreen;
    font-size: 18px;
  }       
  .crud-budget-modal-customer-lost {
    color: darkred;
    font-size: 18px;
  }  
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>
<style>
  .display-template-ql-editor .ql-editor {
    min-height: 350px !important;
  }  
  .budget-template .nav-link:hover {
    text-decoration: underline;
  }
</style>